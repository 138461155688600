<template>
<b-container fluid>
  <b-row class="text-center mb-4" align-h="center" v-if="loading">
    <b-col cols="1">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </b-col>
  </b-row>
  <b-row class="text-white bg-kpmg-blue py-4 mb-4">
    <b-col>
      <h1 class="kstyle">Tags</h1>
    </b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loading">
    <b-col>
      <b-card class="mb-3">
        <b-form-group horizontal class="mt-4">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
              <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
          </b-input-group>
        </b-form-group>
          <b-form inline class="mb-2">
            <b-form-select class="mr-2" v-model="statusSelected" :options="statusOptions"></b-form-select>
          </b-form>
        <b-form inline>
          <span class="mr-2"><strong>Showing {{rowsShow.length}} out of {{rows.length}} Tags</strong></span>
          <b-button class="mr-2" variant="outline-primary" @click="resetFilters">reset filters</b-button>
          <b-button class="mr-2" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'tag' }}">add Tag</b-button>
        </b-form>
      </b-card>
      <div>
        <b-card>
          <div class="mb-3" v-for="row in rowsShow" :key="row.id">
            <tag-simple
              :item="row"
              :username="user.username"
            />
          </div>
        </b-card>
      </div>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

import TagSimple from '@/components/TagSimple.vue'

export default {
  name: 'Tags',
  components: {
    TagSimple
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      rows = _.filter(rows, row => row.del === this.statusSelected)
      if (this.filter.length > 0) {
        rows = rows.filter(x => {
          const term = x.id + ' ' + x.name
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      return rows
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Tags"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'tags', action: 'open tags' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('label').granted
    this.load()
  },
  data () {
    const data = {
      permissions: {
        add: false,
        read: false
      },
      filter: '',
      loading: true,
      rows: [],
      statusOptions: [{ value: false, text: 'active' }, { value: true, text: 'deleted' }],
      statusSelected: false
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.rows = await this.$Amplify.API.get('cosmos', '/standard/label')
        console.log(this.rows)
        this.loading = false
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    resetFilters: function () {
      this.statusSelected = false
    }
  }
}
</script>

<style>
</style>
