<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="mb-2">
            <router-link :to="{ name: 'Tag', params: { id: item.id }}"><h5>{{item.name}}</h5></router-link>
            <div>{{item.description}}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'TagSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('label').granted
    console.log(this.item)
  },
  data () {
    return {
      permission: {
        edit: false
      }
    }
  }
}
</script>

<style>
</style>
